@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: local('Roboto Regular'), local('Roboto-Regular'),
      url('roboto-regular.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: local('Roboto Bold'), local('Roboto-Bold'),
      url('roboto-bold.ttf') format('truetype');
  }
  