/* andadapro-400normal  */
@font-face {
  font-family: 'AndadaPro';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local('AndadaPro Regular'), local('AndadaPro-Regular'),
    url('andadapro-regular.ttf') format('truetype');
}

/* andadapro-400italic  */
@font-face {
  font-family: 'AndadaPro';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: local('AndadaPro Italic'), local('AndadaPro-Italic'),
    url('andadapro-italic.ttf') format('truetype');
}

/* andadapro-500normal  */
@font-face {
  font-family: 'AndadaPro';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: local('AndadaPro Medium'), local('AndadaPro-Medium'),
    url('andadapro-medium.ttf') format('truetype');
}

/* andadapro-500mediumitalic  */
@font-face {
  font-family: 'AndadaPro';
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src: local('AndadaPro MediumItalic'), local('AndadaPro-MediumItalic'),
    url('andadapro-mediumitalic.ttf') format('truetype');
}

/* andadapro-600semibold  */
@font-face {
  font-family: 'AndadaPro';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: local('AndadaPro SemiBold'), local('AndadaPro-SemiBold'),
    url('andadapro-semibold.ttf') format('truetype');
}

/* andadapro-600semibolditalic  */
@font-face {
  font-family: 'AndadaPro';
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src: local('AndadaPro SemiBoldItalic'), local('AndadaPro-SemiBoldItalic'),
    url('andadapro-semibolditalic.ttf') format('truetype');
}

/* andadapro-700bold  */
@font-face {
  font-family: 'AndadaPro';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local('AndadaPro Bold'), local('AndadaPro-Bold'),
    url('andadapro-bold.ttf') format('truetype');
}

/* andadapro-700bolditalic  */
@font-face {
  font-family: 'AndadaPro';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: local('AndadaPro BoldItalic'), local('AndadaPro-BoldItalic'),
    url('andadapro-bolditalic.ttf') format('truetype');
}

/* andadapro-800extrabold  */
@font-face {
  font-family: 'AndadaPro';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: local('AndadaPro ExtraBold'), local('AndadaPro-ExtraBold'),
    url('andadapro-extrabold.ttf') format('truetype');
}

/* andadapro-800extrabolditalic  */
@font-face {
  font-family: 'AndadaPro';
  font-style: italic;
  font-display: swap;
  font-weight: 800;
  src: local('AndadaPro ExtraBoldItalic'), local('AndadaPro-ExtraBoldItalic'),
    url('andadapro-extrabolditalic.ttf') format('truetype');
}
