/* poppins-100thin  */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src: local('Poppins Thin'), local('Poppins-Thin'),
    url('poppins-thin.ttf') format('truetype');
}

/* poppins-100thinitalic  */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-display: swap;
  font-weight: 100;
  src: local('Poppins ThinItalic'), local('Poppins-ThinItalic'),
    url('poppins-thinitalic.ttf') format('truetype');
}

/* poppins-200extralight  */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src: local('Poppins ExtraLight'), local('Poppins-ExtraLight'),
    url('poppins-extralight.ttf') format('truetype');
}

/* poppins-200extralightitalic  */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-display: swap;
  font-weight: 200;
  src: local('Poppins ExtraLightItalic'), local('Poppins-ExtraLightItalic'),
    url('poppins-extralightitalic.ttf') format('truetype');
}

/* poppins-300light  */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: local('Poppins Light'), local('Poppins-Light'),
    url('poppins-light.ttf') format('truetype');
}

/* poppins-300lightitalic  */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src: local('Poppins LightItalic'), local('Poppins-LightItalic'),
    url('poppins-lightitalic.ttf') format('truetype');
}

/* poppins-400regular  */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local('Poppins Regular'), local('Poppins-Regular'),
    url('poppins-regular.ttf') format('truetype');
}

/* poppins-400regularitalic  */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: local('Poppins RegularItalic'), local('Poppins-RegularItalic'),
    url('poppins-italic.ttf') format('truetype');
}

/* poppins-500medium  */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: local('Poppins Medium'), local('Poppins-Medium'),
    url('poppins-medium.ttf') format('truetype');
}

/* poppins-500mediumitalic  */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src: local('Poppins MediumItalic'), local('Poppins-MediumItalic'),
    url('poppins-mediumitalic.ttf') format('truetype');
}

/* poppins-600semibold  */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: local('Poppins SemiBold'), local('Poppins-SemiBold'),
    url('poppins-semibold.ttf') format('truetype');
}

/* poppins-600semibolditalic  */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src: local('Poppins SemiBoldItalic'), local('Poppins-SemiBoldItalic'),
    url('poppins-semibolditalic.ttf') format('truetype');
}

/* poppins-700bold  */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local('Poppins Bold'), local('Poppins-Bold'),
    url('poppins-bold.ttf') format('truetype');
}

/* poppins-700bolditalic  */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: local('Poppins BoldItalic'), local('Poppins-BoldItalic'),
    url('poppins-bolditalic.ttf') format('truetype');
}
